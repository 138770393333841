<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row" v-if="!noHeaders">
        <div class="col-md-4 pl-0">
          <h3>{{ signature.code }}</h3>
          <status-badge
            class="mr-3 p-2 rounded-status-tag"
            :status="signature.status"
          />
        </div>

        <div class="col-md-8 pr-0 text-right">
          <base-button
            :loading="loading"
            class="elite-button btn-outline-success btn-success"
            icon
            size="sm"
            v-if="
              (signature.status == STATUS_DRAFT ||
                signature.status == STATUS_DECLINED ||
                signature.status == STATUS_EXPIRED) &&
              $currentUserCan($permissions.PERM_EDIT_SIGNATURES)
            "
            @click="sendSignature"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-paper-plane"></i>
            </span>
            <span
              class="btn-inner--text"
              v-if="
                signature.status == STATUS_DECLINED ||
                signature.status == STATUS_EXPIRED
              "
            >
              {{ $t("SIGNATURES.RESEND") }}
            </span>
            <span class="btn-inner--text" v-else>
              {{ $t("SIGNATURES.SEND") }}
            </span>
          </base-button>
          <base-button
            class="elite-button btn-outline-danger"
            icon
            size="sm"
            v-if="
              signature.status == STATUS_DRAFT &&
              $currentUserCan($permissions.PERM_DELETE_SIGNATURES)
            "
            @click="deleteSignature"
          >
            <span class="btn-inner--icon">
              <i class="far fa-trash-alt"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.DELETE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="
              (signature.status == STATUS_DRAFT ||
                signature.status == STATUS_DECLINED ||
                signature.status == STATUS_EXPIRED) &&
              $currentUserCan($permissions.PERM_EDIT_SIGNATURES)
            "
            @click="editSignature"
          >
            <span class="btn-inner--icon">
              <i class="far fa-edit"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COMMON.EDIT") }}
            </span>
          </base-button>
          <base-button
            :loading="loading"
            class="elite-button add"
            icon
            size="sm"
            v-if="
              signature.status === STATUS_COMPLETED &&
              (!signature.meta?.signed_documents ||
                signature.meta.signed_documents.length === 0)
            "
            @click="getSignedDocuments"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-download"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("SIGNATURES.GET_DOCUMENTS") }}
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row" v-if="signature.contract?.contract_type">
          <dt>{{ $t("COMMON.TYPE") }}</dt>
          <dd>
            {{
              signature.contract.contract_type
                ? $t("CONTRACTS." + signature.contract.contract_type)
                : ""
            }}
          </dd>
        </dl>

        <dl class="row" v-if="signature.name">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ signature.name ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="signature.description">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            {{ signature.description ?? "" }}
          </dd>
        </dl>

        <dl class="row" v-if="signature.email_message">
          <dt>{{ $t("SIGNATURES.EMAIL_MESSAGE") }}</dt>
          <dd>
            {{ signature.email_message ?? "" }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(signature.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $timeZoneDateFormat(signature.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>

    <div
      v-if="signature.status != STATUS_COMPLETED && signature.contract && signature.contract.token==null"
      class="documents mt-3 mb-3"
    >
      <h3 class="text-danger">{{ $t("SIGNATURES.FILES_TO_BE_SIGN") }}</h3>
      <el-divider />
      <file-list-component
        :contract="signature.contract"
        :generated-documents="signature.meta?.documents_to_sign ?? []"
      />
    </div>

    <div
      v-if="
        signature.status == STATUS_COMPLETED && signature.meta?.signed_documents
      "
      class="documents mt-3 mb-3"
    >
      <h3 class="text-danger">{{ $t("SIGNATURES.SIGNED_FILES") }}</h3>
      <el-divider />
      <files-list :documents="signature.meta?.signed_documents" />
    </div>

    <div v-if="signature.signers.length && signature.signers[0].email" class="payment-method-signers mt-3">
      <h3 class="text-danger">{{ $t("SIGNATURES.SIGNERS") }}</h3>
      <el-divider />
      <object-details
        :data="getSigners(signature.signers)"
        :with-trad="false"
      />
    </div>
  </div>
</template>

<script>
import FileListComponent from "../../ContractManagement/components/files/partials/FileListComponent.vue";
import ObjectDetails from "../components/ObjectDetails.vue";
import StatusBadge from "../components/StatusBadge.vue";
import FilesList from "../components/FilesList.vue";
import { Divider } from "element-ui";
import {
  STATUS_COMPLETED,
  STATUS_DRAFT,
  STATUS_DECLINED,
  STATUS_EXPIRED,
} from "@/constants/signatures";
export default {
  name: "signature-view-global",

  components: {
    FileListComponent,
    ObjectDetails,
    StatusBadge,
    [Divider.name]: Divider,
    FilesList,
  },

  props: {
    signature: {
      type: Object,
      required: true,
    },
    noHeaders: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      STATUS_DRAFT: STATUS_DRAFT,
      STATUS_DECLINED: STATUS_DECLINED,
      STATUS_EXPIRED: STATUS_EXPIRED,
      STATUS_COMPLETED: STATUS_COMPLETED,
    };
  },

  created() {},

  methods: {
    getSigners(signers) {
      return signers.reduce((acc, signer) => {
        const fullName = `${signer.firstname} ${signer.lastname}`;
        acc[fullName] = signer.email;
        return acc;
      }, {});
    },
    signatureUpdated() {
      this.$emit("signatureUpdated", true);
    },
    editSignature() {
      this.$emit("onEditSignature", this.signature);
    },
    getSignedDocuments() {
      this.$emit("onGetSignedDocuments", this.signature);
    },
    deleteSignature() {
      this.$emit("onDeleteSignature", this.signature);
    },
    sendSignature() {
      this.$emit("onSendSignature", this.signature);
    },
  },

  mounted() {},

  watch: {
    signature(signature) {},
  },
};
</script>
