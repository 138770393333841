<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <el-select
          class="select-primary pagination-select"
          v-model="pagination.perPage"
          :placeholder="$t('COMMON.PER_PAGE')"
        >
          <el-option
            class="select-primary"
            v-for="item in pagination.perPageOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <organization-selector
          v-if="
            !filterOrganization &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
          @organizationChanged="
            (organizationId) => (selectedOrganization = organizationId)
          "
        />

        <base-input>
          <el-select
            :label="$t('COMMON.STATUS')"
            :placeholder="$t('COMMON.STATUS')"
            v-model="selectedStatus"
            @change="
              (status) => {
                selectedStatus = status;
              }
            "
          >
            <el-option
              v-for="(signature, val) in SIGNATURE_STATUS"
              :key="val"
              :value="val"
              :label="`${val ? $t('SIGNATURES.' + val) : ''}`"
            />
          </el-select>
        </base-input>

        <base-button
          class="btn base-button refresh btn-icon btn-fab btn-info"
          icon
          size="sm"
          @click="resetFilters"
        >
          <span class="btn-inner--icon">
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.666016 3.46648V7.46648M0.666016 7.46648H4.66602M0.666016 7.46648L3.75935 4.55981C4.47585 3.84296 5.36226 3.31929 6.33589 3.03766C7.30951 2.75604 8.33861 2.72565 9.32716 2.94932C10.3157 3.17299 11.2315 3.64344 11.989 4.31676C12.7466 4.99008 13.3212 5.84434 13.6593 6.79981M15.3327 14.1331V10.1331M15.3327 10.1331H11.3327M15.3327 10.1331L12.2393 13.0398C11.5229 13.7567 10.6364 14.2803 9.66281 14.562C8.68919 14.8436 7.66009 14.874 6.67154 14.6503C5.68299 14.4266 4.76722 13.9562 4.00966 13.2829C3.2521 12.6095 2.67746 11.7553 2.33935 10.7998"
                stroke="white"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </base-button>

        <base-input
          v-model="query"
          type="search"
          prepend-icon="far fa-search"
          :placeholder="$t('COMMON.SEARCH')"
          clearable
        />
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="signatures"
            @sort-change="sortChange"
          >
            <div class="table-loading" slot="empty" v-if="loading">
              <img src="/img/loading.gif" />
            </div>
            <el-table-column :label="$t('COMMON.CODE')" min-width="200">
              <template v-slot="{ row }">
                {{ row.code ?? "" }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.NAME')"
              prop="name"
              sortable="custom"
              min-width="350"
            >
              <template v-slot="{ row }">
                <span>
                  {{ row.name ?? "" }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              :label="$t('COMMON.STATUS')"
              min-width="200"
            >
              <template v-slot="{ row }">
                <status-badge :status="row.status" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.ORGANIZATION')"
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
              min-width="200"
            >
              <template v-slot="{ row }">
                <organization
                  v-if="row.organization"
                  :organization="row.organization"
                />
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('SIGNATURES.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="220"
            >
              <template v-slot="{ row }">
                {{ $timeZoneDateFormat(row.created_at) }}
              </template>
            </el-table-column>

            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_SIGNATURES)"
                >
                  <a
                    type="text"
                    @click="viewSignature(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <img src="/img/expand-icon.svg" alt="icon" />
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="
                    row.status === STATUS_DRAFT
                      ? $t('COMMON.EDIT')
                      : $t('SIGNATURES.CANNOT_EDIT')
                  "
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_SIGNATURES)"
                >
                  <a
                    type="text"
                    @click="row.status === STATUS_DRAFT && editSignature(row)"
                    :class="[
                      'table-action',
                      'table-action-edit',
                      { 'disabled-link': row.status !== STATUS_DRAFT },
                    ]"
                    :style="{
                      cursor:
                        row.status === STATUS_DRAFT ? 'pointer' : 'not-allowed',
                    }"
                  >
                    <img src="/img/edit-icon.svg" alt="icon" />
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="
                    row.status === STATUS_DRAFT
                      ? $t('COMMON.DELETE')
                      : $t('SIGNATURES.CANNOT_DELETE')
                  "
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_SIGNATURES)"
                >
                  <a
                    type="text"
                    @click="
                      row.status === STATUS_DRAFT && deleteSignature(row.id)
                    "
                    :class="[
                      'table-action',
                      'table-action-delete',
                      { 'disabled-link': row.status !== STATUS_DRAFT },
                    ]"
                    :style="{
                      cursor:
                        row.status === STATUS_DRAFT ? 'pointer' : 'not-allowed',
                    }"
                  >
                    <img src="/img/delete-icon.svg" alt="icon" />
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import { STATUS_DRAFT, SIGNATURE_STATUS } from "@/constants/signatures";
import StatusBadge from "../components/StatusBadge.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";

export default {
  name: "signature-list-table",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    StatusBadge,
    OrganizationSelector,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      selectedStatus: null,
      SIGNATURE_STATUS: SIGNATURE_STATUS,
      STATUS_DRAFT: STATUS_DRAFT,
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      signatures: [],
      loading: true,
      selectedOrganization: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    selectedStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    resetFilters() {
      this.query = null;
      this.selectedStatus = null;
      this.selectedOrganization = null;
    },

    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        if (this.selectedStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatus,
            },
          };
        }

        await this.$store.dispatch("signatures/list", params);
        this.signatures = this.$store.getters["signatures/list"];
        this.total = this.$store.getters["signatures/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editSignature(signature) {
      this.$emit("onEditSignature", signature);
    },

    async deleteSignature(id) {
      const confirmation = await swal.fire({
        text: this.$t("SIGNATURES.DELETE_THIS_SIGNATURE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("signatures/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("SIGNATURES.SIGNATURE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewSignature(signature) {
      this.$emit("onViewSignature", signature);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
