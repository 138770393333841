export default {
  type: "signatures",
  code:null,
  name: null,
  description: null,
  email_message: null,
  status: null,
  object_type: null,
  object_id: null,
  signers: [],
  sent_at: null,
  meta: null,
  relationshipNames: ["organization", "contract", "object"],
  organization: {
    type: "organizations",
    id: null,
  },
  contract: {
    type: "contracts",
    id: null,
  },
};
