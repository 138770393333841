<template>
  <el-table
    class="object-detail-table"
    :data="formattedData"
    :show-header="false"
    stripe
    border
  >
    <el-table-column prop="label" :min-width="labelColumnWidth">
      <template slot-scope="scope">
        <strong>{{ scope.row.label }}</strong>
      </template>
    </el-table-column>
    <el-table-column prop="value" align="left">
      <template slot-scope="scope">
        <div v-if="isStatus(scope.row.value)">
          <status-badge :status="scope.row.value" />
        </div>
        <div v-else-if="isDate(scope.row.value)">
          {{ $timeZoneDateFormat(scope.row.value) }}
        </div>
        <div v-else>
          {{ scope.row.value }}
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import { STATUS_LIST_ARRAY } from "@/constants/signatures";
import StatusBadge from "./StatusBadge.vue";
export default {
  name: "ObjectDetails",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    StatusBadge,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    withTrad: {
      type: Boolean,
      default: true,
    },
    tradPath: {
      type: String,
      default: "SIGNATURES",
    },
    labelColumnWidth: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      STATUS_LIST: STATUS_LIST_ARRAY,
    };
  },
  computed: {
    formattedData() {
      // Conversion de l'objet en tableau de paires clé-valeur
      return Object.keys(this.data).map((key) => ({
        label: this.withTrad ? this.$t(`${this.tradPath}.${key}`) : key, // Trad ou clé elle-même
        value: this.data[key] || "", // La valeur
      }));
    },
  },
  methods: {
    isStatus(value) {
      // Vérifie si la valeur est dans STATUS_LIST
      return value != "" && this.STATUS_LIST.includes(value);
    },
    isDate(value) {
      // Vérifie si la valeur correspond au format ISO 8601
      const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/;
      return value != "" && iso8601Regex.test(value);
    },
  },
};
</script>
