var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contract-fields-form"},[_c('el-form',{ref:"contract_fields_form",attrs:{"model":_vm.form}},_vm._l((_vm.form.documents),function(document,docIndex){return _c('div',{key:docIndex},[_c('h3',[_vm._v(_vm._s(_vm.$t("SIGNATURES.DOCUMENT"))+" : "+_vm._s(document.document_name))]),_vm._l((document.approvals),function(approval,appIndex){return _c('div',{key:appIndex},[_c('el-divider'),_c('div',{staticClass:"row"},[_vm._l((_vm.getFieldsByType(
              approval.fields,
              'TEXTFIELD'
            )),function(field,fieldIndex){return [_c('div',{key:'text-' + fieldIndex,staticClass:"col-md-4"},[_c('el-form-item',{attrs:{"label":field.tooltip,"prop":_vm.getProp(docIndex, appIndex, field.id),"rules":[
                  {
                    required: field.required,
                    message: _vm.$t('COMMON.FIELD_REQUIRED'),
                  },
                ]}},[_c('el-input',{model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)],1)]}),_vm._l((_vm.getFieldsByType(
              approval.fields,
              'LIST'
            )),function(field,fieldIndex){return [_c('div',{key:'list-' + fieldIndex,staticClass:"col-md-4"},[_c('el-form-item',{attrs:{"label":field.tooltip,"prop":_vm.getProp(docIndex, appIndex, field.id),"rules":[
                  {
                    required: field.required,
                    message: _vm.$t('COMMON.FIELD_REQUIRED'),
                  },
                ]}},[_c('el-select',{attrs:{"placeholder":"Sélectionner"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},_vm._l((field.options),function(option){return _c('el-option',{key:option,attrs:{"label":option,"value":option}})}),1)],1)],1)]})],2),_c('div',{staticClass:"row"},[_vm._l((_vm.getRadioGroups(approval.fields)),function(group,groupIndex){return [_c('div',{key:'radio-group-' + groupIndex,staticClass:"col-md-12"},[_c('el-form-item',{attrs:{"label":group.groupTooltip}},[_c('el-radio-group',{model:{value:(group.selected),callback:function ($$v) {_vm.$set(group, "selected", $$v)},expression:"group.selected"}},_vm._l((group.fields),function(field,fieldIndex){return _c('el-radio',{key:'radio-' + fieldIndex,attrs:{"label":field.id},on:{"change":function($event){return _vm.handleRadioChange(group.fields, field.id)}}},[_vm._v(" "+_vm._s(field.tooltip)+" ")])}),1)],1)],1)]})],2),_c('div',{staticClass:"row"},[_vm._l((_vm.getFieldsByType(
              approval.fields,
              'CHECKBOX'
            )),function(field,fieldIndex){return [_c('div',{key:'checkbox-' + fieldIndex,staticClass:"col-md-4"},[_c('el-form-item',[_c('el-checkbox',{model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}},[_vm._v(_vm._s(field.tooltip))])],1)],1)]}),_vm._l((_vm.getFieldsByType(
              approval.fields,
              'TEXTAREA'
            )),function(field,fieldIndex){return [_c('div',{key:'textarea-' + fieldIndex,staticClass:"col-md-12"},[_c('el-form-item',{attrs:{"label":field.tooltip,"prop":_vm.getProp(docIndex, appIndex, field.id),"rules":[
                  {
                    required: field.required,
                    message: _vm.$t('COMMON.FIELD_REQUIRED'),
                  },
                ]}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(field.value),callback:function ($$v) {_vm.$set(field, "value", $$v)},expression:"field.value"}})],1)],1)]})],2)],1)})],2)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }