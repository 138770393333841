<template>
  <div class="contract-fields-form">
    <el-form ref="contract_fields_form" :model="form">
      <div v-for="(document, docIndex) in form.documents" :key="docIndex">
        <h3>{{ $t("SIGNATURES.DOCUMENT") }} : {{ document.document_name }}</h3>

        <div v-for="(approval, appIndex) in document.approvals" :key="appIndex">
          <!-- <h4>{{ $t("SIGNATURES.ROLE") }} : {{ approval.role }}</h4> -->
          <el-divider />
          <div class="row">
            <!-- TEXTFIELD -->
            <template
              v-for="(field, fieldIndex) in getFieldsByType(
                approval.fields,
                'TEXTFIELD'
              )"
            >
              <div class="col-md-4" :key="'text-' + fieldIndex">
                <el-form-item
                  :label="field.tooltip"
                  :prop="getProp(docIndex, appIndex, field.id)"
                  :rules="[
                    {
                      required: field.required,
                      message: $t('COMMON.FIELD_REQUIRED'),
                    },
                  ]"
                >
                  <el-input v-model="field.value" />
                </el-form-item>
              </div>
            </template>

            <!-- LIST -->
            <template
              v-for="(field, fieldIndex) in getFieldsByType(
                approval.fields,
                'LIST'
              )"
            >
              <div class="col-md-4" :key="'list-' + fieldIndex">
                <el-form-item
                  :label="field.tooltip"
                  :prop="getProp(docIndex, appIndex, field.id)"
                  :rules="[
                    {
                      required: field.required,
                      message: $t('COMMON.FIELD_REQUIRED'),
                    },
                  ]"
                >
                  <el-select v-model="field.value" placeholder="Sélectionner">
                    <el-option
                      v-for="option in field.options"
                      :key="option"
                      :label="option"
                      :value="option"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </div>

          <!-- Groupement des RADIO -->
          <div class="row">
            <template
              v-for="(group, groupIndex) in getRadioGroups(approval.fields)"
            >
              <div class="col-md-12" :key="'radio-group-' + groupIndex">
                <el-form-item :label="group.groupTooltip">
                  <el-radio-group v-model="group.selected">
                    <el-radio
                      v-for="(field, fieldIndex) in group.fields"
                      :key="'radio-' + fieldIndex"
                      :label="field.id"
                      @change="handleRadioChange(group.fields, field.id)"
                    >
                      {{ field.tooltip }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
            </template>
          </div>

          <div class="row">
            <!-- CHECKBOX -->
            <template
              v-for="(field, fieldIndex) in getFieldsByType(
                approval.fields,
                'CHECKBOX'
              )"
            >
              <div class="col-md-4" :key="'checkbox-' + fieldIndex">
                <el-form-item>
                  <el-checkbox v-model="field.value">{{
                    field.tooltip
                  }}</el-checkbox>
                </el-form-item>
              </div>
            </template>

            <!-- TEXTAREA -->
            <template
              v-for="(field, fieldIndex) in getFieldsByType(
                approval.fields,
                'TEXTAREA'
              )"
            >
              <div class="col-md-12" :key="'textarea-' + fieldIndex">
                <el-form-item
                  :label="field.tooltip"
                  :prop="getProp(docIndex, appIndex, field.id)"
                  :rules="[
                    {
                      required: field.required,
                      message: $t('COMMON.FIELD_REQUIRED'),
                    },
                  ]"
                >
                  <el-input v-model="field.value" type="textarea" />
                </el-form-item>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  Divider,
  Option,
  Select,
  Form,
  FormItem,
  Checkbox,
  Radio,
  RadioGroup,
  Input,
} from "element-ui";
import cloneDeep from "lodash/cloneDeep";

export default {
  components: {
    [Divider.name]: Divider,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Checkbox.name]: Checkbox,
    [Input.name]: Input,
  },

  props: {
    value: Array,
  },

  data() {
    return {
      form: {
        id: null,
        documents: [],
      },
      isValid:false,
    };
  },

  watch: {
    form: {
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue.documents); // Met à jour le parent
      },
    },
  },

  created() {
    this.form.documents = cloneDeep(this.value);
  },

  methods: {
    getFieldsByType(fields, type) {
      return fields.filter((field) => field.subtype === type);
    },

    getRadioGroups(fields) {
      const groups = {};
      fields.forEach((field) => {
        if (field.subtype === "RADIO" && field.options.length > 0) {
          const groupKey = field.options[0];

          if (!groups[groupKey]) {
            groups[groupKey] = {
              groupTooltip: field.groupTooltip || "Choisissez une option",
              selected: null,
              fields: [],
            };
          }
          groups[groupKey].fields.push(field);

          // Met à jour la sélection initiale
          if (field.value === "x") {
            groups[groupKey].selected = field.id;
          }
        }
      });
      return Object.values(groups);
    },

    handleRadioChange(fields, selectedId) {
      fields.forEach((field) => {
        field.value = field.id === selectedId ? "x" : null;
      });
    },

    getProp(docIndex, appIndex, fieldId){
        return 'documents.' + docIndex + '.approvals.' + appIndex + '.fields.' + this.getIndex(docIndex, appIndex, fieldId) + '.value';
    },

    getIndex(docIndex, appIndex, fieldId) {
      const field = this.form.documents[docIndex].approvals[
        appIndex
      ].fields.find((f) => f.id === fieldId);
      if (field) {
        return this.form.documents[docIndex].approvals[appIndex].fields.indexOf(
          field
        );
      } else {
        return -1;
      }
    },

    checkInformation() {
      this.$refs["contract_fields_form"].validate(async (valid) => {
        if (valid) {
          this.isValid = true;
        }
      });
      return this.isValid;
    },

  },
};
</script>
