<template>
  <div class="files-list">
    <div v-for="document in documents" :key="document.url" class="mb-3">
      <a :href="document.url" target="_blank">
        <el-card shadow="always" class="document-card-content">
          <div class="d-flex align-items-start">
            <!-- Icône devant le texte -->
            <div class="icon-container">
              <i v-if="document.ext === 'pdf'" class="fas fa-file-pdf"></i>
              <i
                v-else-if="document.ext === 'word'"
                class="fas fa-file-word"
              ></i>
              <i
                v-else-if="document.ext === 'excel'"
                class="fas fa-file-excel"
              ></i>
              <i
                v-else-if="document.ext === 'image'"
                class="fas fa-file-image"
              ></i>
              <i
                v-else-if="document.ext === 'text'"
                class="fas fa-file-alt"
              ></i>
              <i v-else class="fas fa-file"></i>
            </div>
            <!-- Texte du contrat -->
            <div>
              <h4 class="contract-title">{{ document.name }}</h4>
            </div>
          </div>
        </el-card>
      </a>
    </div>
  </div>
</template>

<script>
import { Card } from "element-ui";
export default {
  name: "FilesList",
  components: {
    [Card.name]: Card,
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },
};
</script>
