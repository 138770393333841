<template>
  <div class="container-fluid mt-5">
    <modal :show="showModal" modal-classes="elite-modal file-view" size="lg">
      <div
        class="col-12 justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="row mb-1">
          <h1>
            <a target="_blank" :href="getFileUrl(fileData)">
              {{ `${fileData.name}` }}
            </a>
          </h1>
        </div>
        <div class="row mb-1 actions">
          <button v-if="showOptions" class="btn edit ml-1" @click="onEditFile">
            <i class="fas fa-edit"></i>
          </button>
          <button v-if="showOptions" class="btn delete btn-danger ml-1" @click="onDeleteFile">
            <i class="fas fa-trash"></i>
          </button>
          <a v-if="getFileUrl(fileData)" target="_blank" :href="getFileUrl(fileData)" class="btn download ml-1">
            <i class="fa fa-download"></i>
            {{ $t("FILES.DOWNLOAD") }}
          </a>
        </div>
        <div class="elite-tabs-wrapper">
          <tabs
            fill
            class="flex-column flex-md-row"
            tabNavWrapperClasses="nav-wrapper"
            tabNavClasses="nav  elite-tabs"
            value="global"
          >
            <tab-pane title="global" id="1" :active="true">
              <span slot="title">
                <i class="ni ni-cloud-upload-96"></i>
                {{ $t("COMMON.GLOBAL") }}
              </span>
              <file-view-modal-global
                :file="fileData"
                @fileUpdated="fileUpdated"
                @editFile="onEditFile"
                @deleteFile="onDeleteFile"
              />
            </tab-pane>

            <tab-pane
              title="logs"
              id="999"
              v-if="showOptions && $currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
            >
              <span slot="title">
                <i class="fa fa-file"></i>
                {{ $t("COMMON.LOGS") }}
              </span>
              <file-view-modal-logs :file="fileData" />
            </tab-pane>
          </tabs>
        </div>
      </div>
      <template slot="footer">
        <button
          type="button"
          class="btn close btn-link ml-auto"
          @click="closeModal()"
        >
          {{ $t("COMMON.CLOSE") }}
        </button>
      </template>
    </modal>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import FileViewModalGlobal from "./FileViewModalGlobal.vue";
import FileViewModalLogs from "./FileViewModalLogs.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    FileViewModalLogs,
    FileViewModalGlobal,
  },

  mixins: [],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: null,
      description: "Files linked object",
    },
    fileData: {
      type: Object,
      default: null,
      description: "File data",
    },
    showOptions: {
      type: Boolean,
      default: true,
    },
    generatedDocuments: {
      type: Array,
      default: () => [],
      description: "For generated files",
    },
  },

  data() {
    return {
      tagsUpdated: false,
    };
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    getGeneratedDocument(fileId) {
      // Retourne un document généré correspondant au file_id
      return (
        this.generatedDocuments?.find((doc) => doc.file_id === parseInt(fileId)) || null
      );
    },

    getFileUrl(file) {
      // Chercher le document généré s'il existe
      const generatedDoc = this.getGeneratedDocument(file.id);
      console.log(generatedDoc);

      // Retourner l'URL s'il existe, sinon retourner l'URL par défaut
      return generatedDoc?.url || file.url || null;
    },

    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh || this.tagsUpdated);
    },

    fileUpdated() {
      this.tagsUpdated = true;
      this.$emit("fileUpdated", true);
    },

    onEditFile() {
      this.$emit("editFile", this.fileData.id);
    },

    onDeleteFile() {
      this.$emit("deleteFile", this.fileData.id);
    },
  },
};
</script>
